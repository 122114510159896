import moment from 'moment-timezone';

import config from '@/lib/config';
import { shiftTimezone } from '@/lib/dateHelpers';
import { removeEmpty } from '@/lib/utility';

export function createClient(instance) {
  return {
    booking: {
      getAll() {
        return instance.get('/users/self/bookings');
      },

      daily: {
        getOne(id, params = {}) {
          return instance.get(`/bookings/${id}`, { params });
        },

        getAll() {
          return instance.get('/users/self/bookings/daily');
        },

        createOrUpdate(id = null, data = {}) {
          if (!id) {
            return instance.put('/bookings', data);
          }

          return instance.post(`/bookings/${id}`, data);
        },

        createUserCheckIn(id) {
          return instance.post(`/bookings/${id}/checkin`);
        },

        cancel(id) {
          return instance.delete(`/bookings/${id}`);
        },

        guest: {
          add(bookingId, guestData = {}) {
            return instance.put(`/guests/daily/add/${bookingId}`, guestData);
          },

          edit(guestId, guestName) {
            return instance.post(`/guests/daily/${guestId}`, {
              update: { name: guestName },
            });
          },

          delete(guestId) {
            return instance.delete(`/guests/daily/${guestId}`);
          },

          getBookingWithHash(bookingHash) {
            return instance.get(`/guests/retrieve-booking/${bookingHash}`);
          },

          confirmPresence(guestId, { hash, tosAccepted, marketingOptIn }) {
            return instance.post(`/guests/daily/${guestId}/confirm`, {
              hash,
              tosAccepted,
              marketingOptIn,
            });
          },

          cancelPresence(guestId, { hash }) {
            return instance.post(`/guests/daily/${guestId}/cancel`, {
              hash,
            });
          },
        },
      },

      hourly: {
        getOne(id, params = {}) {
          return instance.get(`/hourly-bookings/${id}`, { params });
        },

        createOrUpdate(id = null, data = {}) {
          if (!id) {
            return instance.put('/hourly-bookings', data);
          }

          return instance.post(`/hourly-bookings/${id}`, data);
        },

        cancel(id) {
          return instance.delete(`/hourly-bookings/${id}`);
        },
      },

      office: {
        getOne(id, params = {}) {
          return instance.get(`/office-bookings/${id}`, { params });
        },

        createOrUpdate(id = null, data = {}) {
          if (!id) {
            return instance.put('/office-bookings', data);
          }

          return instance.post(`/office-bookings/${id}`, data);
        },

        estimateTotal({ roomId, start, end, ...data }) {
          return instance.get(`/office-bookings/estimate-total`, {
            params: {
              roomId,
              start: moment(start).format(),
              end: moment(end).format(),
              ...data,
            },
          });
        },

        cancel(id) {
          return instance.delete(`/office-bookings/${id}`);
        },
      },
    },

    space: {
      amenities: {
        getAll() {
          return instance.get('/spaces/tags');
        },
      },

      getAvailabilityOnDate(space, date, params = {}) {
        // Put together default start/end for availability query if start/end not
        // passed explicitly through request args object
        let { start, end } = params;

        if (!start || !end) {
          const momentDate = moment(date || new Date());

          start = moment
            .tz(momentDate, space.timezone)
            .startOf('month')
            .format();
          end = moment
            .tz(momentDate, space.timezone)
            .endOf('month')
            .startOf('day')
            .format();
        }

        return instance.get(`/spaces/${space.id}/availability-intervals`, {
          params: {
            ...params,
            start,
            end,
          },
        });
      },

      getCapacityOnDate(space, date) {
        // If date wasn't passed, assume current date in space's timezone
        if (!date) {
          date = moment();
        }
        // Make sure date is in correct timezone for requesting capacity
        date = shiftTimezone(date.startOf('day'), space.timezone).format();

        return instance.get(`/spaces/${space.id}/current-daily-capacity`, {
          params: { date },
        });
      },

      getOne(slugOrId, params = {}) {
        return instance.get(`/spaces/${slugOrId}`, {
          params: {
            userTime: moment().format(),
            ...params,
          },
        });
      },

      getAll(params = {}) {
        return instance.get('/spaces', { params });
      },
    },

    room: {
      amenities: {
        getAll() {
          return instance.get('/rooms/tags');
        },
      },

      getOne(slugOrId, spaceSlugOrId, params = {}) {
        return instance.get(`/rooms/${slugOrId}`, {
          params: {
            spaceSlugOrId,
            userTime: moment().format(),
            ...params,
          },
        });
      },

      getAll(params = {}) {
        return instance.get('/rooms', { params });
      },

      getAvailabilityIntervals(roomId, start, end, options = {}) {
        return instance.get(`/rooms/${roomId}/availability-intervals`, {
          params: {
            start: moment(start).format(),
            end: moment(end).format(),
            ...options,
          },
        });
      },
    },

    geocoding: {
      getPlace(placeId) {
        return instance.get(`/users/geocoding/place/${placeId}`);
      },

      search(search, params = {}) {
        return instance.get(`/users/geocoding/search/${search}`, {
          params: removeEmpty(params),
        });
      },
    },

    city: {
      getOne(citySlugOrId) {
        return instance.get(`/cities/${citySlugOrId}`);
      },

      getAll(params = {}) {
        // Default to cities in 'Merica
        if (
          !params.countryId &&
          !params.countrySlug &&
          !params.countryCode &&
          !params.countryFromCitySlug
        ) {
          params.countryCode = 'US';
        }

        return instance.get('/cities', { params });
      },
    },

    user: {
      getSelf() {
        return instance.get('/users/self');
      },

      updateSelf(data) {
        return instance.post('/users/self', data);
      },

      getHourlyBookingMessageUpdates() {
        return instance.get('/users/self/bookings/hourly/updates');
      },

      getOfficeBookingMessageUpdates() {
        return instance.get('/users/self/bookings/office/updates');
      },

      signup(data = {}) {
        return instance.put('/users', data);
      },

      confirmEmail(hash) {
        return instance.get(`/users/confirm-email/${hash}`);
      },

      resetPassword({ hash, password }) {
        return instance.post('/users/reset-password', { hash, password });
      },

      verifyPasswordResetToken(hash) {
        return instance.get(`/users/forgot-password/confirm/${hash}`);
      },

      recoverForgottenPassword(email, source) {
        return instance.get('/users/forgot-password/create', {
          params: {
            email,
            ...(source && { source }),
          },
        });
      },

      getActiveCoupon() {
        return instance.get(`/stripe/active-coupon`);
      },

      getReceipts(params = {}) {
        return instance.get('/users/billing', {
          params: removeEmpty(params, { emptyString: true }),
          // Use full response as this relies on pagination
          transformData: ({ data }) => data,
        });
      },

      getReceiptCsvUrl(params = {}) {
        return instance.get('/users/billing/csv', { params });
      },

      canBookOnSpace(spaceId, params = {}) {
        return instance.get(
          `/users/self/can-create-bookings/${spaceId}/daily`,
          { params },
        );
      },

      canBookOnRoom(roomId, { start, end } = {}) {
        return instance.get(
          `/users/self/can-create-bookings/${roomId}/hourly`,
          {
            params: {
              start,
              end,
            },
            // Use base instance to get full response
            // Response has no body so we return only the { success: true }
            transformData: ({ data }) => data,
          },
        );
      },

      getTeamInvitation(hash) {
        return instance.get(`/teams/team-members/invite/${hash}`);
      },

      isValidCoupon(coupon) {
        return instance.get(`/stripe/${coupon}/valid`);
      },

      addFavoriteSpace(id) {
        return instance.put(`/users/self/favorite-space/${id}`);
      },

      addFavoriteRoom(id) {
        return instance.put(`/users/self/favorite-room/${id}`);
      },

      removeFavoriteSpace(id) {
        return instance.delete(`/users/self/favorite-space/${id}`);
      },

      removeFavoriteRoom(id) {
        return instance.delete(`/users/self/favorite-room/${id}`);
      },

      searchTeamUsers(query, limit = 5) {
        return instance.get(`/teams/team-members/search`, {
          params: {
            query,
            limit,
            excludeSelfUser: true,
          },
        });
      },
    },

    auth: {
      getOtp() {
        return instance.post('/auth/otp', {
          meta: { transitionTo: 'TeamManager' },
        });
      },

      verification: {
        stripe: {
          request() {
            return instance.post('/auth/verification/stripe/request');
          },
        },
      },

      hubspot: {
        contactToken() {
          return instance.post('/hubspot/conversations/token');
        },
      },
    },

    global: {
      getConfig() {
        return instance.get('/config');
      },

      getCountries(params = {}) {
        return instance.get('/countries', { params });
      },

      uploadImage({ file, folder, subfolder }) {
        const formData = new FormData();

        formData.append('upload', file);
        formData.append('folder', folder);
        formData.append('subfolder', subfolder);

        return instance.post('/files/image', formData);
      },
    },

    checkHeartbeat() {
      return instance.get('/heartbeat', {
        baseURL: config.API_URL, // Route doesn't have /api namespace
        retryConfig: {
          interval: 1000,
          backoffPolicy: null,
          retryCount: Infinity,
        },
      });
    },
  };
}
